<template>
  <BRow>
    <BCol lg="12">
      <BCard>
        <span class="font-medium text-black text-2xl">Performa Pertumbuhan User (Partner)</span>
        <BRow class="mt-1">
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="@/assets/images/svg/note.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Registrasi</span>
                </div>
              </div>
              <div
                v-if="data && data.Registrasi"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.Registrasi.total_register }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.Registrasi.register_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.Registrasi.register_yesterday }} Kemarin
                  </div>
                </div>
              </div>
              <div
                v-else
                class="h-[103px]"
              />
            </div>
          </Bcol>
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="@/assets/images/svg/tick-square.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Verifikasi</span>
                </div>
              </div>
              <div
                v-if="data && data.verifikasi"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.verifikasi.total_verified }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.verifikasi.verified_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.verifikasi.verified_yesterday }} Kemarin
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/onboarding.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Onboarding</span>
                </div>
              </div>
              <div
                v-if="data && data.onboarding"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.onboarding.total_onboardig }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.onboarding.onboarding_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.onboarding.onboarding_yesterday }} Kemarin
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/house-2.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Add Gudang</span>
                </div>
              </div>
              <div
                v-if="data && data.partner_add_gudang"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.partner_add_gudang.total_user_gudang }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.partner_add_gudang.gudang_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.partner_add_gudang.gudang_yesterday }} Kemarin
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/box-add.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Add Produk</span>
                </div>
              </div>
              <div
                v-if="data && data.partner_add_product"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.partner_add_product.total }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.partner_add_product.total_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.partner_add_product.total_yesterday }} Kemarin
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="4">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/status-up.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Add Order</span>
                </div>
              </div>
              <div
                v-if="data && data.potensial"
                class="mt-2 flex"
              >
                <span class="text-black text-[25px] border-r pr-2">
                  {{ data.potensial.total_potensial }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ data.potensial.potensial_today }} Hari ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ data.potensial.potensial_yesterday }} Kemarin
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="6">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1 min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/chart.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Pickup New User</span>
                </div>
                <button
                  :disabled="loading"
                  :class="loading ? 'cursor-not-allowed' : ''"
                  class="border-[#E2E2E2] p-[3px] rounded-[6px] border-solid border-2"
                  @click="$router.push('/admin-dashboard-komship/user-growth-performance')"
                >
                  <img src="https://storage.googleapis.com/komerce/assets/icons/arrow-right-2.svg">
                </button>
              </div>
              <div v-if="data && data.aktif">
                <div class="mt-2 flex">
                  <span class="text-black text-[25px] border-r pr-2">
                    {{ data.aktif.total_user_active }}
                    <div class="text-sm">Lifetime</div>
                  </span>
                  <div class="ml-1">
                    <div class="text-[#34A270]">
                      + {{ data.aktif.active_today }} Hari ini
                    </div>
                    <div class="text-[#08A0F7]">
                      + {{ data.aktif.active_yesterday }} Kemarin
                    </div>
                  </div>
                </div>
                <div class="flex space-x-2">
                  <div class="text-sm text-black">
                    Bulan lalu:
                    <span class="text-[#08A0F7]">+ {{ data.aktif.active_user_last_month }}</span>
                  </div>
                  <div class="text-sm text-black">
                    Bulan ini:
                    <span class="text-[#34A270]">+ {{ data.aktif.active_user_this_month }}</span>
                  </div>
                </div>
              </div>
            </div>
          </BCol>
          <BCol lg="3" md="6">
            <div class="border border-[#e2e2e2] rounded-2xl shadow p-1 mb-1  min-h-[155px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <BSpinner
                    v-if="loading || byFilter"
                    small
                    variant="primary"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/komerce/assets/svg/monitor-mobbile.svg"
                    alt="Komerce"
                  >
                  <span class="text-lg text-black ml-[5px]">Pickup Bulan Ini</span>
                </div>
              </div>
              <div v-if="data && data.user_aktif">
                <div class="my-[10px] flex">
                  <span class="text-black text-[25px] border-r pr-2">
                    {{ data.user_aktif.total_user_aktive }}
                  </span>
                  <div class="ml-1">
                    <div class="text-[#34A270]">
                      {{ data.user_aktif.new_user_active }}% User Baru ({{ data.user_aktif.sum_new_active_user }})
                    </div>
                    <div class="text-[#08A0F7]">
                      {{ data.user_aktif.user_active_last_month }}% User Lama ({{ data.user_aktif.sum_old_active_user }})
                    </div>
                  </div>
                </div>
                <MonthlyPicker
                  v-model="filter"
                  date-format="MMM YYYY"
                  place-holder="Pilih Bulan"
                  :month-labels="monthlabel"
                  :max="maxDatePicker"
                  style="margin: auto"
                />
              </div>
            </div>
          </BCol>
        </BRow>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCard, BSpinner } from 'bootstrap-vue'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import MonthlyPicker from 'vue-monthly-picker'
import { END_DATE_OF_MONTH, LABELMONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'

export default {
  components: {
    BRow, BCard, BSpinner, MonthlyPicker,
  },
  data() {
    return {
      data: {},
      loading: false,
      filter: this.$moment(),
      monthlabel: LABELMONTH,
      byFilter: false,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    filter: {
      handler() {
        this.byFilter = true
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const cacheKey = 'kom-ugp'
        const existingCache = JSON.parse(localStorage.getItem(cacheKey))
        if (existingCache && !this.byFilter) {
          if (new Date(existingCache.expired).getTime() - new Date().getTime() > 0) {
            this.data = existingCache.data
            return
          }
          localStorage.removeItem(cacheKey)
        }
        if (!this.byFilter) {
          this.loading = true
        }
        const params = {
          start_date: START_DATE_OF_MONTH(this.filter),
          end_date: END_DATE_OF_MONTH(this.filter),
        }
        const url = '/v1/admin/dashboard/users-growth-performance'
        const res = await komshipAxiosIns.get(url, { params })
        this.data = res.data.data
        if (!this.byFilter) {
          const newCacheTime = new Date()
          newCacheTime.setTime(newCacheTime.getTime() + 2 * 60 * 60 * 1000)
          const newCache = {
            data: res.data.data,
            expired: newCacheTime,
          }
          localStorage.setItem(cacheKey, JSON.stringify(newCache))
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err.response.message,
            variant: 'danger',
          },
        }, 2000)
      } finally {
        this.loading = false
        this.byFilter = false
      }
    },
  },
}
</script>

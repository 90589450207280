<template>
  <div>
    <PerformanceUser />
    <Expedition />
    <HistoryRetur />
    <ChartTransaction />
  </div>
</template>

<script>
import PerformanceUser from './components/PerformanceUser.vue'
import Expedition from './components/Expedition.vue'
import HistoryRetur from './components/HistoryRetur.vue'
import ChartTransaction from './components/ChartTransaction.vue'

export default {
  components: {
    PerformanceUser, Expedition, HistoryRetur, ChartTransaction,
  },
}
</script>
